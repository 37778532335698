import { Grid, Typography, Button } from "@mui/material";
import AppointmentCard from "components/AppointmentCard";
import useAuthContext from "hooks/useAuth";
import React, { useEffect, useState } from "react";
import useStyles from "./style";
import * as scheduleApi from 'API/schedule';
import { ScheduleModel } from "models/ScheduleModel";
import Backdrop from "components/Backdrop";
import useSnackbarContext from "hooks/useSnackbar";
import Confirm from "components/Modal/Confirm";
import { NotificationsActive } from "@mui/icons-material";
import { Messages } from "settings";
import { useNavigate } from "react-router-dom";

const Home = (prop: any) => {
    const classes = useStyles();
    const { auth } = useAuthContext();
    const [scheduleList, setScheduleList] = useState<ScheduleModel[]>([]);
    const [working, setWorking] = useState(false);
    const [scheduleId, setScheduleId] = useState<number | undefined>();
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<number>(0);
    const { open: openSnack } = useSnackbarContext();
    const navigate = useNavigate();

    useEffect(() => {
        setWorking(true);
        scheduleApi.listByUser()
            .then(setScheduleList)
            .catch(() => openSnack({ open: true, message: 'Ocurrio un error al obtener turnos, vuelva a intentar', severity: 'error' }))
            .finally(() => setWorking(false));
    }, [refresh])

    const cancelAppointment = (sId?: number) => {
        if (sId) {
            setWorking(true);
            scheduleApi.cancel(sId)
                .then(res => {
                    if (res) {
                        setRefresh(refresh + 1);
                        openSnack({ open: true, severity: 'info', message: 'Turno cancelado' });
                    } else
                        openSnack({ open: true, severity: 'info', message: 'No se pudo cancelar el turno' });
                })
                .catch(() => openSnack({ open: true, severity: 'info', message: Messages.ERROR_COMM }))
                .finally(() => setWorking(false));
        }
    }

    return (
        <>
            <Backdrop open={working} />
            <div className={classes.root}>
                <Grid container justifyContent='center' alignItems={'center'}>
                    <Grid item>
                        <Typography className={classes.title}>¡Bienvenido <strong className={classes.user}>{auth?.fullname}</strong>!</Typography>
                    </Grid>
                </Grid>
                <div style={{ width: '100%', height: '2rem' }}></div>
                <Grid container justifyContent='center' alignItems={'center'}>
                    <Grid item>
                        <Button variant='contained' onClick={() => navigate('/client/new-appointment')}>Nuevo turno</Button>
                    </Grid>
                </Grid>
                <div style={{ width: '100%', height: '2rem' }}></div>
                <Grid container justifyContent='center' alignItems={'center'}>
                    <Grid item>
                        <Typography className={classes.title}><strong>Turnos agendados: {scheduleList.length}</strong></Typography>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.appointments}>
                <Grid container justifyContent={'center'} spacing={3}>
                    {
                        scheduleList.map((item, key) => {
                            return (
                                <Grid item key={key}>
                                    <AppointmentCard cancel={() => { setScheduleId(item.scheduleId); setConfirmModal(true) }} scheduleData={item} isHistory={false}></AppointmentCard>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </div>
            <Confirm
                open={confirmModal}
                close={() => setConfirmModal(false)}
                confirm={() => cancelAppointment(scheduleId)}
                message='¿Está seguro de cancelar el turno?'
                icon={NotificationsActive}></Confirm>
        </>
    )
}

export default Home;