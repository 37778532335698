import { Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./style";
import 'react-calendar/dist/Calendar.css';
import palette from "style-variables";
import BackButton from "components/Buttons/BackButton";
import { useLocation, useNavigate } from "react-router-dom";
import { ProfessionalModel } from "models/ProfessionalModel";
import { MedicalSpecialityModel } from "models/MedicalSpecialityModel";
import * as scheduleApi from 'API/schedule';
import useSnackbarContext from "hooks/useSnackbar";
import { ScheduleAvailabilityModel } from "models/ScheduleAvailabilityModel";
import Backdrop from "components/Backdrop";
import dayjs from "dayjs";
import { EventAvailable } from "@mui/icons-material";

const Information = ({ title, data }: any) => {

    return (
        <>
            {title && <Typography style={{ color: palette.$primary_text }}>{title}</Typography>}
            {data && <Typography style={{ color: palette.$primary_text }}><strong>{data}</strong></Typography>}
        </>
    )
}

interface StateProp {
    professionalId?: number,
    professionalName?: string,
    specialityId?: number,
    specialityName?: string,
    date: Date
}

const Choose = (prop: any) => {
    const [working, setWorking] = useState(false);
    const [availabilityList, setAvailabilityList] = useState<ScheduleAvailabilityModel[]>([]);
    const [vprofessionalName, setvProfessionalName] = useState<string | undefined>();
    const [vspecialityName, setvSpecialityName] = useState<string | undefined>();
    const [vdate, setvDate] = useState<Date>();
    const classes = useStyles();
    const navigate = useNavigate();
    const { state }: any = useLocation();
    const { open: openSnack } = useSnackbarContext();

    useEffect(() => {
        if (!state) {
            navigate('/client/home');
            return;
        }
        const { professionalId, professionalName, specialityId, specialityName, date }: StateProp = state;
        if ((!professionalId && !specialityId) || !date)
            navigate('/client/home');

        setWorking(true);
        setvProfessionalName(professionalName);
        setvSpecialityName(specialityName);
        setvDate(date);
        scheduleApi.listAvailabilityDaily(date, specialityId, professionalId)
            .then(res => {
                if (res.length == 0)
                    openSnack({ open: true, message: 'No hay turnos disponinbles', severity: 'info' });
                setAvailabilityList(res);
            })
            .catch(() => openSnack({ open: true, message: 'Ocurrio un error al obtener turnos disponibles, vuelva a intentar', severity: 'error' }))
            .finally(() => setWorking(false));

    }, [state])

    const onSelected = (id: number, time: string, professionalName: string) => {
        navigate('/client/new-appointment/confirm',
            {
                state: {
                    scheduleAvailabilityId: id,
                    professionalName: professionalName,
                    date: vdate,
                    time: time
                }
            })
    }
    return (
        <>
            <Backdrop open={working} />
            <div className={classes.appointments}>

                <Grid container justifyContent={'center'} alignItems='start' spacing={3} flexDirection={{ xs: 'column', sm: 'row' }}>
                    {vprofessionalName && <Grid item><Information title={'Profesional'} data={vprofessionalName} /></Grid>}
                    {vspecialityName && <Grid item><Information title={'Especialidad'} data={vspecialityName} /></Grid>}
                    <Grid item><Information title={'Fecha'} data={dayjs(vdate).format('DD/MM/YYYY')} /></Grid>
                </Grid>
                <div style={{ width: '100%', height: '2rem' }}></div>
                <Grid container justifyContent={'center'}>
                    <Grid item><Information title={'Seleccionar hora:'} /></Grid>
                </Grid>
                <div style={{ width: '100%', height: '1rem' }}></div>
                <Grid container justifyContent={'center'} spacing={2} alignItems={'center'}>
                    {
                        availabilityList.map((item, key) => {
                            return (
                                <Grid item container key={key}>
                                    {/* <Grid item xs={3}><Information data={`${item.time}hs`} /></Grid> */}
                                    <Grid item xs={8}><Information data={`${item.professionalName}`} /></Grid>
                                    <Grid item xs={4}>
                                        <Button onClick={() => onSelected(item.scheduleAvailabilityId, item.time, item.professionalName)} variant='contained'>
                                            {item.time}hs. <EventAvailable></EventAvailable>
                                        </Button>
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                    <div style={{ width: '100%', height: '3rem' }}></div>
                    <Grid item >
                        <BackButton></BackButton>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default Choose;