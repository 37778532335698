import React, { useEffect, useRef, useState } from 'react';
import { AccountCircle, Email, Phone } from '@mui/icons-material';
import { Button, Grid, InputAdornment, Typography } from '@mui/material';
import Input from 'components/Form/Input';
import { useForm } from 'react-hook-form';
import useStyles from './style';
import BackButton from 'components/Buttons/BackButton';
import { RegisterModel } from 'models/RegisterModel';
import useSnackbarContext from 'hooks/useSnackbar';
import * as userApi from 'API/user';
import { Messages } from 'settings';
import Backdrop from 'components/Backdrop';
import { UserUpdateModel } from 'models/UserUpdateModel';

interface RegisterData extends RegisterModel {
    repeatEmail: string
}

const Profile = (props: any) => {
    const { control, register, handleSubmit, reset, watch } = useForm<RegisterData>({
        defaultValues: {
            dni: '',
            email: '',
            password: '',
            firstname: '',
            lastname: '',
            phone: '',
            repeatEmail: '',
        }
    });
    const email = useRef({});
    email.current = watch("email", "");
    const classes = useStyles();
    const [working, setWorking] = useState(false);
    const { open: openSnack } = useSnackbarContext();

    useEffect(() => {
        setWorking(true);
        userApi.get()
            .then(reset)
            .catch(() => openSnack({ open: true, message: Messages.ERROR_COMM, severity: 'error' }))
            .finally(() => setWorking(false));
    }, [])

    const onSubmit = (data: RegisterModel) => {
        var d = data as UserUpdateModel;
        setWorking(true);
        userApi.updateProfile(d)
            .then(res => {
                if (res)
                    openSnack({ open: true, message: Messages.SAVED, severity: 'success' });
                else
                    openSnack({ open: true, message: Messages.CANT_SAVE, severity: 'warning' });
            })
            .catch(() => openSnack({ open: true, message: Messages.ERROR_COMM, severity: 'error' }))
            .finally(() => setWorking(false));
    }

    return (
        <div className={classes.root}>
            <Backdrop open={working}></Backdrop>
            <Grid container justifyContent='center' alignItems={'center'}>
                <Grid item>
                    <Typography className={classes.title}>Modificá tus datos</Typography>
                </Grid>
            </Grid>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={{ xs: 3, sm: 2 }} justifyContent='center' alignItems={'center'} flexDirection={{ xs: 'column', sm: 'row' }}>
                    <div style={{ width: '100%', height: '1rem' }}></div>
                    <Grid item xs={12} sm={6}>
                        <Input
                            disabled
                            fullWidth
                            name='firstname'
                            label='Nombre'
                            register={register('firstname', { required: 'Este campo es requerido' })}
                            control={control}
                            variant={'filled'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <AccountCircle />
                                    </InputAdornment>

                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            disabled
                            fullWidth
                            name='lastname'
                            label='Apellido'
                            register={register('lastname', { required: 'Este campo es requerido' })}
                            control={control}
                            variant={'filled'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <AccountCircle />
                                    </InputAdornment>

                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            disabled
                            fullWidth
                            name='dni'
                            label='DNI'
                            register={register('dni', { required: 'Este campo es requerido' })}
                            control={control}
                            variant={'filled'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <AccountCircle />
                                    </InputAdornment>

                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            fullWidth
                            name='phone'
                            label='Teléfono'
                            register={register('phone', { required: 'Este campo es requerido' })}
                            control={control}
                            variant={'filled'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <Phone />
                                    </InputAdornment>

                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            fullWidth
                            name='email'
                            label='Correo electrónico'
                            register={register('email', { required: 'Este campo es requerido' })}
                            control={control}
                            variant={'filled'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <Email />
                                    </InputAdornment>

                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            fullWidth
                            name='repeatEmail'
                            label='Repetir correo electrónico'
                            register={register('repeatEmail', {
                                required: 'Este campo es requerido',
                                validate: value => {
                                    var p = watch('email') || ''; return value === p || 'Los emails no son iguales'
                                }
                            })}
                            control={control}
                            variant={'filled'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <Email />
                                    </InputAdornment>

                            }}
                        />
                    </Grid>

                    <Grid item>
                        <Button type={'submit'} variant='contained' onClick={() => handleSubmit(onSubmit)}>modificar</Button>
                    </Grid>

                    <div style={{ width: '100%', height: '3rem' }}></div>
                    <Grid item >
                        <BackButton></BackButton>
                    </Grid>
                </Grid>

            </form>
        </div>
    )
};

export default Profile;