import React, { useRef, useState } from 'react';
import { AccountCircle, Email, Password, Phone } from '@mui/icons-material';
import { Button, Grid, InputAdornment, Typography } from '@mui/material';
import Input from 'components/Form/Input';
import { useForm } from 'react-hook-form';
import useStyles from './style';
import BackButton from 'components/Buttons/BackButton';
import { ChangePasswordModel } from 'models/ChangePasswordModel';
import useSnackbarContext from 'hooks/useSnackbar';
import * as userApi from 'API/user';
import { Messages } from 'settings';
import Backdrop from 'components/Backdrop';

interface ChangePasswordData extends ChangePasswordModel {
    repeatPassword: string
}

const ChangePassword = (props: any) => {
    const { control, register, handleSubmit, reset, watch } = useForm<ChangePasswordData>();
    const classes = useStyles();
    const [working, setWorking] = useState(false);
    const { open: openSnack } = useSnackbarContext();

    const email = useRef({});
    email.current = watch("newPassword", "");

    const onSubmit = (data: ChangePasswordData) => {
        var d = data as ChangePasswordModel;
        setWorking(true);
        userApi.changePassword(data)
            .then(res => {
                if (res) {
                    openSnack({ open: true, message: Messages.SAVED, severity: 'success' });
                    reset();
                }
                else
                    openSnack({ open: true, message: Messages.CANT_SAVE, severity: 'warning' });
            })
            .catch(() => openSnack({ open: true, message: Messages.ERROR_COMM, severity: 'error' }))
            .finally(() => setWorking(false));
    }

    return (
        <div className={classes.root}>
            <Backdrop open={working}></Backdrop>
            <Grid container justifyContent='center' alignItems={'center'}>
                <Grid item>
                    <Typography className={classes.title}>Cambiá tu contraseña</Typography>
                </Grid>
            </Grid>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={{ xs: 3, sm: 2 }} justifyContent='center' alignItems={'center'} flexDirection={{ xs: 'column', sm: 'row' }}>
                    <div style={{ width: '100%', height: '1rem' }}></div>

                    <Grid item xs={12}>
                        <Input
                            fullWidth
                            name='oldPassword'
                            label='Contraseña anterior'
                            register={register('oldPassword', { required: 'Este campo es requerido' })}
                            control={control}
                            variant={'filled'}
                            type={'password'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <AccountCircle />
                                    </InputAdornment>

                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Input
                            fullWidth
                            name='newPassword'
                            label='Nueva contraseña'
                            register={register('newPassword', { required: 'Este campo es requerido' })}
                            control={control}
                            variant={'filled'}
                            type={'password'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <Password />
                                    </InputAdornment>

                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            fullWidth
                            name='repeatPassword'
                            label='Repetir nueva contraseña'
                            type={'password'}
                            register={register('repeatPassword', {
                                required: 'Este campo es requerido',
                                validate: value => {
                                    var p = watch('newPassword') || ''; return value === p || 'Las contraseñas no son iguales'
                                }
                            })}
                            control={control}
                            variant={'filled'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <Password />
                                    </InputAdornment>

                            }}
                        />
                    </Grid>

                    <Grid item>
                        <Button onClick={() => handleSubmit(onSubmit)} type={'submit'} variant='contained'>modificar</Button>
                    </Grid>

                    <div style={{ width: '100%', height: '3rem' }}></div>
                    <Grid item >
                        <BackButton></BackButton>
                    </Grid>
                </Grid>

            </form>
        </div>
    )
};

export default ChangePassword;