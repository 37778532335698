import axios, { AxiosRequestConfig, AxiosInstance } from "axios";
import { AuthModel } from "models/AuthModel";

const APP_LOGGED_KEY = 'app.user.ppgascon';

export const createApi = (config?: AxiosRequestConfig): AxiosInstance => {
    const api = axios.create(config);

    // Add a request interceptor
    api.interceptors.request.use(
        config => {
            const store = localStorage.getItem(APP_LOGGED_KEY);
            const auth: AuthModel | undefined =
                store === null || store === undefined || store === 'LOGGED_OUT' ?
                    undefined :
                    JSON.parse(localStorage.getItem(APP_LOGGED_KEY) || '') as AuthModel;

            if (auth && auth.token && config.headers) {
                config.headers['Authorization'] = 'Bearer ' + auth.token;
            }
            return config;
        },
        error => {
            ///si no esta autorizado, lo mando al login
            if (error.response.status === 401) {
                window.location.href = '/logout';
            }
            Promise.reject(error)
        });

    //capture response
    api.interceptors.response.use(
        undefined,
        error => {
            ///si no esta autorizado, lo mando al login
            if (error.response.status === 401) {
                window.location.href = '/logout';
            }

            // if (error.response.status === 404)
            //     window.location.href = '/home';

            Promise.reject(error)
        });
    return api;
}
export default createApi;