import { AccountCircle, Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, Grid, InputAdornment, Typography } from '@mui/material';
import Backdrop from 'components/Backdrop';
import Input from 'components/Form/Input';
import useAuthContext from 'hooks/useAuth';
import useSnackbarContext from 'hooks/useSnackbar';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useStyles from './style';

interface LoginData {
    dni: string,
    password: string
}

const Login = (props: any) => {
    const [showPassword, setShowPassword] = useState(false);
    const { control, register, handleSubmit, reset } = useForm<LoginData>();
    const classes = useStyles();
    const navigate = useNavigate();
    const { login, isAuth } = useAuthContext();
    const [working, setWorking] = useState(false);
    const { open: openSnack } = useSnackbarContext();

    useEffect(() => {
        if (isAuth)
            navigate('/client/home');
    }, [isAuth])

    const onSubmit = async (data: LoginData) => {
        setWorking(true);
        login(data.dni, data.password)
        .then((userlogged)=>{
            if (userlogged === -1) {
                openSnack({ open: true, message: 'Usuario o contraseña incorrectos', severity: 'error' });
                setWorking(false);
            }
        })
        .catch(()=>openSnack({ open: true, message: 'Usuario o contraseña incorrectos', severity: 'error' }))
        .finally(()=>setWorking(false))
    }

    return (
        <div className={classes.root}>
            <Backdrop open={working}></Backdrop>
            <Grid container justifyContent='center' alignItems={'center'}>
                <Grid item>
                    <Typography className={classes.title}>¡Bienvenido!</Typography>
                </Grid>
            </Grid>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3} justifyContent='center' alignItems={'center'} flexDirection={'column'}>
                    <div style={{ width: '100%', height: '1rem' }}></div>
                    <Grid item xs={12}>
                        <Input
                            fullWidth
                            name='dni'
                            label='DNI'
                            register={register('dni', { required: 'Este campo es requerido' })}
                            control={control}
                            variant={'filled'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <AccountCircle />
                                    </InputAdornment>

                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            fullWidth
                            name="password"
                            label="Contraseña"
                            type={showPassword ? 'text' : 'password'}
                            control={control}
                            register={register('password', { required: 'Este campo es requerido' })}
                            variant={'filled'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <div style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)}>
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </div>
                                    </InputAdornment>

                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant='contained' type='submit' onClick={handleSubmit(onSubmit)}>ingresar</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <a className={classes.forgotMyPass} onClick={() => { navigate('/recover-password') }}>Olvidé mi contraseña</a>
                    </Grid>
                    <div style={{ width: '100%', height: '1rem' }}></div>
                    <Grid item xs={12}>
                        <a className={classes.signin} onClick={() => { navigate('/register') }}>Registrarme</a>
                    </Grid>
                </Grid>

            </form>
        </div>
    )
};

export default Login;