import createApi from 'API';
import { ScheduleAvailabilityModel } from 'models/ScheduleAvailabilityModel';
import { ScheduleModel } from 'models/ScheduleModel';

let endpoint = (window as { [key: string]: any })["runConfig"].api.medicalCenter as string;

const api = createApi({
    baseURL: endpoint
});

export const listAvailability = async (medicalSpecialityId?: number, professionalId?: number): Promise<ScheduleAvailabilityModel[]> => {
    var route = '';
    if (medicalSpecialityId)
        route = `schedule/list-availability-by-speciality/${medicalSpecialityId}`;

    if (professionalId)
        route = `schedule/list-availability-by-professional/${professionalId}`;

    return api.get(`${route}`)
        .then(res => {
            return res.data as ScheduleAvailabilityModel[];
        });
};

export const listAvailabilityDaily = async (date: Date, medicalSpecialityId?: number, professionalId?: number): Promise<ScheduleAvailabilityModel[]> => {
    var route = '';
    if (medicalSpecialityId)
        route = `schedule/list-availability-daily-by-speciality/${medicalSpecialityId}`;

    if (professionalId)
        route = `schedule/list-availability-daily-by-professional/${professionalId}`;

    return api.get(`${route}?date=${date.toISOString()}`)
        .then(res => {
            return res.data as ScheduleAvailabilityModel[];
        });
};

/**asigna un turno al usuario activo */
export const userAdd = async (scheduleId: number): Promise<boolean> => {
    return api.put(`schedule/${scheduleId}/user-add`)
        .then(res => {
            return res.data as boolean;
        });
};


export const listHistoryByUser = async (): Promise<ScheduleModel[]> => {
    return api.get(`schedule/list-history-by-user`)
        .then(res => {
            return res.data as ScheduleModel[];
        });
};

export const listByUser = async (): Promise<ScheduleModel[]> => {
    return api.get(`schedule/list-by-user`)
        .then(res => {
            return res.data as ScheduleModel[];
        });
};

/**cancela el turno del usuario activo */
export const cancel = async (scheduleId: number): Promise<boolean> => {
    return api.put(`schedule/${scheduleId}/cancel`)
        .then(res => {
            return res.data as boolean;
        });
};