import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import palette from 'style-variables';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            color: `${palette.$primary_text}!important`,
            fontSize: '1.2rem!important'
        },
        user: {
            color: `${palette.$primary}!important`,
        },
        appointments: {
            padding: '2rem'
        },
        calendar: {
            border: `2px solid ${palette.$primary_text}!important`,
            borderRadius:'10px',
            '& .react-calendar__navigation__arrow, .react-calendar__navigation__label': {
                borderBottom: `2px solid ${palette.$primary_text}!important`,
            }
        }
    })
);
export default useStyles;