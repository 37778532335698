import Search from './Search';
import Choose from './Choose';
import Confirm from './Confirm';
import { Grid, Typography } from '@mui/material';
import useStyles from './style';
import { Outlet } from 'react-router-dom';

const NewAppointment = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container justifyContent='center' alignItems={'center'}>
                <Grid item>
                    <Typography className={classes.title}>Agendá un nuevo turno</Typography>
                </Grid>
            </Grid>
            <div style={{ width: '100%', height: '2rem' }}></div>
            <Outlet></Outlet>
        </div>
    )
}
export default NewAppointment;

export {
    Search,
    Choose,
    Confirm
};