import {  logIn as userLogin } from 'API/auth';
import { AuthModel } from 'models/AuthModel';
import React, {
    useReducer,
    useCallback,
    createContext,
    useMemo,
    useEffect,
    useState,
} from 'react';

const LOGGED_OUT = 'LOGGED_OUT';
const LOADING = 'LOADING';

const APP_LOGGED_KEY = 'app.user.ppgascon';

var authd: AuthModel | undefined;
export const AuthContext = createContext({
    login: async (user: string, password: string): Promise<number> => -1,
    logout: () => { },
    auth: authd,
    isAuth: false,
    isLoading: false
});

const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';
const SET_STATE = 'SET_STATE';

function reducer(state: any, action: any) {
    switch (action.type) {
        case LOGIN:
            return action.payload;
        case LOGOUT:
            return LOGGED_OUT;
        case SET_STATE:
            return action.payload;
        default:
            return state;
    }
}

export const AuthContextProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(reducer, LOADING);
    const [authData, setAuthData] = useState<AuthModel>();

    /**autentica a un usuario */
    const login = useCallback(async (user: string, password: string): Promise<number> => {
        var data = await userLogin(user, password);
        if (data === null)
            return -1; //usuario no existe
        setAuthData(data)
        localStorage.setItem(APP_LOGGED_KEY, JSON.stringify(data));
        dispatch({ type: LOGIN, payload: JSON.stringify(data) });
        return 0; //ok
    }, [dispatch]);

    /**cierra la sesión del usuario actual */
    const logout = useCallback(() => {
        localStorage.setItem(APP_LOGGED_KEY, LOGGED_OUT);
        dispatch({ type: LOGOUT });
    }, [dispatch]);

    const contextValue = useMemo(() => (
        {
            login,
            logout,
            auth: authData,
            isAuth: state !== LOGGED_OUT,
            isLoading: state === LOADING
        }
    ), [login, logout, state, authData]);

    useEffect(() => {
        async function fetchState() {
            const status = localStorage.getItem(APP_LOGGED_KEY);

            if (status && status !== LOGGED_OUT) {
                var data = JSON.parse(status);
                setAuthData(data as AuthModel);
            }

            dispatch({
                type: SET_STATE,
                payload: status ?? LOGGED_OUT,
            });
        }
        fetchState();
    }, []);

    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    );
}

export default AuthContext;
