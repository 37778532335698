import React, { useState, useRef, useEffect } from 'react';
import { AccountCircle } from '@mui/icons-material';
import { Button, Grid, IconButton, InputAdornment, Typography } from '@mui/material';
import Input from 'components/Form/Input';
import { useForm } from 'react-hook-form';
import useStyles from './style';
import BackButton from 'components/Buttons/BackButton';
import { useNavigate, useParams } from 'react-router-dom';
import * as authApi from 'API/auth';
import Backdrop from 'components/Backdrop';
import useSnackbarContext from 'hooks/useSnackbar';
import { ResetPasswordModel } from 'models/ResetPasswordModel';
import { Messages } from 'settings';

const ResetPassword = (props: any) => {
    const { token } = useParams();
    const navigate = useNavigate();
    const { register, handleSubmit, control, watch, reset } = useForm<any>();
    const classes = useStyles();
    const { open: openSnack } = useSnackbarContext();
    const [working, setWorking] = useState(false);

    const password = useRef({});
    password.current = watch("password", "");

    useEffect(() => {
        const d = async () => {
            if (token === undefined || token === null || token === '')
                navigate('/not-found');
            if (token)
                authApi.tokenIsValid(token)
                    .then(res => {
                        if (!res)
                            navigate('/not-found');
                    })
        }
        d();
    }, [token])

    const onSubmit = (data: any) => {
        var resetPass: ResetPasswordModel = {
            password: data.password,
            token: token ?? ''
        };

        setWorking(true);
        if (token)
            authApi.resetPassword(resetPass)
                .then((res) => {
                    if (res) {
                        openSnack({ open: true, severity: "success", message: "Se generó la contraseña con éxito." });
                        setTimeout(() => {
                            navigate('/login');
                        }, 2000)
                    }
                    else
                        openSnack({ open: true, severity: "warning", message: "No se pudo generar la contraseña, vuelva a intentar más tarde" });

                })
                .catch(() => openSnack({ open: true, severity: "error", message: Messages.ERROR_COMM }))
                .finally(() => setWorking(false));
    };

    return (
        <div className={classes.root}>
            <Backdrop open={working}></Backdrop>

            <Grid container justifyContent='center' alignItems={'center'}>
                <Grid item>
                    <Typography className={classes.title}>Generá tu nueva contraseña</Typography>
                </Grid>
            </Grid>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3} justifyContent='center' alignItems={'center'} flexDirection={'column'}>
                    <div style={{ width: '100%', height: '1rem' }}></div>
                    <Grid item xs={12}>
                        <Input
                            fullWidth
                            autoFocus
                            name="password"
                            label="Nueva contraseña"
                            type={'password'}
                            inputProps={{ maxLength: 255 }}
                            control={control}
                            register={register("password", {
                                required: "Este campo es requerido",
                                maxLength: {
                                    value: 255,
                                    message: "No puede superar los 255 caracteres.",
                                },
                            })}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            fullWidth
                            name="repeatpassword"
                            control={control}
                            label="Repetir Contraseña"
                            inputProps={{ maxLength: 255 }}
                            type={'password'}
                            variant='outlined'
                            register={register('repeatpassword', {
                                validate: value => {
                                    var p = password.current || '';
                                    return value === p || 'Las contraseñas no son iguales'
                                }
                            })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant='contained' type='submit'>Generar contraseña</Button>
                    </Grid>
                    
                    <div style={{ width: '100%', height: '3rem' }}></div>
                    <Grid item xs={12}>
                        <BackButton></BackButton>
                    </Grid>
                </Grid>

            </form>
        </div>
    )
};

export default ResetPassword;