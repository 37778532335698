import createApi from 'API';
import { AuthModel } from 'models/AuthModel';
import { ChangePasswordModel } from 'models/ChangePasswordModel';
import { RegisterModel } from 'models/RegisterModel';
import { UserUpdateModel } from 'models/UserUpdateModel';

let endpoint = (window as { [key: string]: any })["runConfig"].api.auth as string;

const api = createApi({
    baseURL: endpoint
});

export enum SaveResult {
    ok = 0,
    dniExists = 1
}

/**obtiene los datos del usuario activo */
export const get = async (): Promise<RegisterModel> => {
    return api.get('user')
        .then(res => {
            return res.data as RegisterModel;
        });
};

/**cambia los datos del usuario activo */
export const updateProfile = async (data: UserUpdateModel): Promise<boolean> => {
    return api.put('user', data)
        .then(res => {
            return res.data as boolean;
        });
};

/**cambia la clave del usuario activo */
export const changePassword = async (data: ChangePasswordModel): Promise<boolean> => {
    return api.put('user/change-password', data)
        .then(res => {
            return res.data as boolean;
        });
};