import { Button, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./style";
import 'react-calendar/dist/Calendar.css';
import palette from "style-variables";
import BackButton from "components/Buttons/BackButton";
import { useLocation, useNavigate } from "react-router-dom";
import useSnackbarContext from "hooks/useSnackbar";
import { MedicalSpecialityModel } from "models/MedicalSpecialityModel";
import { ProfessionalModel } from "models/ProfessionalModel";
import dayjs from "dayjs";
import * as scheduleApi from 'API/schedule';
import { Messages } from "settings";
import Backdrop from "components/Backdrop";

const Information = ({ title, data }: any) => {
    return (
        <>
            {title && <Typography style={{ color: palette.$primary_text }}>{title}</Typography>}
            {data && <Typography style={{ color: palette.$primary_text }}><strong>{data}</strong></Typography>}
        </>
    )
}

interface StateProp {
    professionalName: string,
    date: Date,
    time: string,
    scheduleAvailabilityId: number
}

const Confirm = (prop: any) => {
    const classes = useStyles();
    const { state }: any = useLocation();
    const navigate = useNavigate();
    const [availabilityId, setAvailabilityId] = useState<number>(0);
    const [vprofessionalName, setvProfessionalName] = useState<string>();
    const [vdate, setvDate] = useState<Date>();
    const [vtime, setvTime] = useState<string>();
    const [working, setWorking] = useState(false);

    const { open: openSnack } = useSnackbarContext();

    useEffect(() => {
        if (!state) {
            navigate('/client/home');
            return;
        }
        const { professionalName, date, scheduleAvailabilityId, time }: StateProp = state;
        if (!professionalName || !date || !scheduleAvailabilityId || !time)
            navigate('/client/home');

        setAvailabilityId(scheduleAvailabilityId);
        setvProfessionalName(professionalName);
        setvDate(date);
        setvTime(time);

    }, [state])

    const onConfirm = () => {
        if (availabilityId == 0) {
            openSnack({ open: true, message: 'Debe seleccionar un horario', severity: 'info' })
            navigate(-1);
            return;
        }

        setWorking(true);
        scheduleApi.userAdd(availabilityId)
            .then(res => {
                if (res) {
                    openSnack({ open: true, severity: 'success', message: '¡Turno asignado!' });
                    navigate('/client/appointments');
                }
                else
                    openSnack({ open: true, severity: 'warning', message: 'Turno no disponible' })
            })
            .catch(() => openSnack({ open: true, severity: 'error', message: Messages.ERROR_COMM }))
            .finally(() => setWorking(false));
    }
    return (
        <>
            <Backdrop open={working}></Backdrop>

            <div className={classes.appointments}>
                <Grid container justifyContent={'center'} spacing={2} alignItems={'center'}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container justifyContent={'center'} alignItems='start' spacing={3} flexDirection={{ xs: 'column' }}>
                                <Grid item><Information title={'Profesional'} data={vprofessionalName} /></Grid>
                                <Grid item><Information title={'Fecha'} data={dayjs(vdate).format('DD//MM/YYYY')} /></Grid>
                                <Grid item><Information title={'Hora'} data={vtime} /></Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <div style={{ width: '100%', height: '1rem' }}></div>
                <Grid container justifyContent={'center'} spacing={2} alignItems={'center'}>
                    <Grid item >
                        <Button variant='contained' onClick={onConfirm}>confirmar</Button>
                    </Grid>
                </Grid>
                <div style={{ width: '100%', height: '2rem' }}></div>
                <Grid container justifyContent={'center'} spacing={2} alignItems={'center'}>
                    <Grid item >
                        <BackButton></BackButton>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default Confirm;