import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import useAuthContext from 'hooks/useAuth';
import { Outlet, useNavigate } from 'react-router-dom';
import { Divider, Grid } from '@mui/material';
import { Role } from 'settings';
import useStyles from './style';
import palette from 'style-variables';
import logo from 'assets/logo.jpg'
import logoResponsive from 'assets/logo-responsive.jpg'

interface PagesModel {
  name: string,
  route: string,
  role?: number[],
}

const pages: PagesModel[] = [
  { name: 'Home', route: '/client/home' },
  { name: 'Nuevo Turno', route: '/client/new-appointment' },
  { name: 'Mis turnos', route: '/client/appointments' },
  { name: 'Perfil', route: '/client/profile' },
  { name: 'Cambiar Contraseña', route: '/client/change-password' },
  { name: 'Salir', route: '/logout' },
];

const settings: PagesModel[] = [
  /*  { name: 'Perfil', route: 'profile', role: [Role.eventManager, Role.eventViewer] }, */
]

const Layout = (props: any) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const classes = useStyles();
  const { isAuth, auth } = useAuthContext();
  const navigate = useNavigate();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <AppBar position="fixed" color={'secondary'}>
        <Container maxWidth="xl">
          <Toolbar className={classes.toolbar} disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="div"
              onClick={() => navigate('/home')}
              sx={{ flexGrow: 12, mr: 2, display: { xs: 'none', md: 'flex' } }}
            >
              <img className={classes.logo} src={logo}></img>
            </Typography>

            {/**Pantalla responsive */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                className={classes.menuResponsive}
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{ display: { xs: 'block', md: 'none' } }}
              >
                {pages.filter(p => !p.role).map((page, index) =>
                  <MenuItem key={index} onClick={() => {navigate(page.route); handleCloseNavMenu()}}>
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                )}
                {pages.filter(p => p.role && p.role.length > 0 && auth && p.role.includes(auth?.roleId)).map((page, index) =>
                  <MenuItem key={index} onClick={() => {navigate(page.route); handleCloseNavMenu()}}>
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                )}

              </Menu>
            </Box>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
            >
              <img className={classes.logo} src={logo}></img>
            </Typography>
            {/**Fin Pantalla responsive */}

            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.filter(p => !p.role).map((page, index) => (
                <Button
                  key={index}
                  onClick={() => navigate(page.route)}
                  sx={{ my: 2, color: palette.$primary, display: 'block' }}
                >
                  {page.name}
                </Button>
              ))}
              {pages.filter(p => p.role && p.role.length > 0 && auth && p.role.includes(auth?.roleId)).map((page, index) => (
                <Button
                  key={index}
                  onClick={() => navigate(page.route)}
                  sx={{ my: 2, color: palette.$primary, display: 'block' }}
                >
                  {page.name}
                </Button>
              ))}
            </Box>
            {/* {
              isAuth &&
              < Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Typography
                      variant="h6"
                      noWrap
                      textAlign="end"
                      component="div"
                      sx={{ color: 'white' }}>
                      Mi cuenta </Typography>
                      <div style={{width:'1rem'}}> </div>
                    <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                  </IconButton>
                </Tooltip>
                <Menu
                  className={classes.menuFrame}
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  keepMounted
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.filter(s => s.role && s.role.length > 0 && auth && s.role.includes(auth?.roleId)).map((setting, index) => (
                    <MenuItem style={{ borderBottom: ' 1px solid white', height: '100%' }} key={index} onClick={() => { navigate(setting.route); handleCloseUserMenu(); }}>
                      <Typography textAlign="center">{setting.name}</Typography>
                      <Divider variant="middle" />
                    </MenuItem>
                  ))}
                  {settings.filter(s => !s.role).map((setting, index) => (
                    <MenuItem style={{ borderBottom: ' 1px solid white', height: '100%' }} key={index} onClick={() => { navigate(setting.route); handleCloseUserMenu(); }}>
                      <Typography textAlign="center">{setting.name}</Typography>
                    </MenuItem>
                  ))}


                </Menu>
              </Box>
            } */}
          </Toolbar>
        </Container>
      </AppBar>


      <div style={{ height: '7.37rem' }}></div>
      {/* childrens */}

      <Outlet></Outlet>

    </>
  );
};
export default Layout;
