export const Messages = {
    CANT_DELETE: 'No se pudo eliminar, probablemente porque esté siendo utilizado',
    ERROR_COMM: 'Ocurrió un error. Vuelva a intentar o comuníquese con soporte',
    DELETED: 'Eliminado con éxito',
    SAVED: 'Información guardada',
    CANT_SAVE: 'No se pudo guardar la información, vuelva a intentar',
}

export const REGEX =
{
    REGEX_MAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    REGEX_URL: /^(https?):\/\/(-\.)?([^\s\\/?\.#-%=]+\.?)+(\/[^\s]*)?$/,
    REGEX_NUMBER_NATURAL: /^[0-9]*$/,
    REGEX_NUMBER_TWO_DECIMALS: /^[0-9]+(.[0-9]{0,2})?$/,
    REGEX_NUMBER_EIGHT_DECIMALS: /^[0-9]+(.[0-9]{0,8})?$/,
    REGEX_NUMBER_INT_EIGHT_DECIMALS: /^-?[0-9]+(.[0-9]{0,8})?$/,
    REGEX_NUMBER_INT_FOUR_DECIMALS: /^-?[0-9]+(.[0-9]{0,4})?$/,
    INT32_MAX: 2147483648,
    INT16_MAX: 32768,
    EXPIRATIONDATE: /^[0-9]{0,2}-([0-9]{0,2})?$/,
    CARDNUMBER:/^[0-9\s]*$/
}

export const enum Role {
    admin = 255,
    eventManager = 1,
    eventViewer = 2
}

