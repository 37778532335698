import { Button, Card, CardContent, CardHeader, CardActions, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import { ScheduleModel } from "models/ScheduleModel";
import React from "react";
import useStyles from "./style";

interface AppointmentCardProps {
    isHistory: boolean
    scheduleData: ScheduleModel,
    cancel?: () => void
}

const AppointmentCard = (props: AppointmentCardProps) => {
    const { isHistory } = props;
    const classes = useStyles();


    return (
        <Card className={!isHistory ? classes.root : classes.rootHistory}>
            <CardHeader
                className={!isHistory ? classes.header : classes.headerHistory}
                title={props.scheduleData.medicalSpeciality}
            />
            <CardContent className={classes.cardContent}>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography className={classes.date}>
                            {dayjs(props.scheduleData.date).format('dddd')}
                        </Typography>
                        <Typography className={classes.date}>
                            {isHistory ? dayjs(props.scheduleData.date).format('DD/MM/YYYY') : dayjs(props.scheduleData.date).format('DD/MM')}
                        </Typography>
                        <Typography className={classes.time}>{props.scheduleData.time} hs</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.professional}>Profesional</Typography>
                        <Typography className={classes.professionalName}>{props.scheduleData.professional}</Typography>
                        {/* <div style={{ width: '100%', height: '0.5rem' }}></div> */}
                    </Grid>
                </Grid>
            </CardContent>
            {!props.isHistory &&
                <CardActions style={{ marginLeft: '1rem' }}>
                    <Button onClick={props.cancel} style={{ textTransform: 'none' }} variant="contained" className={classes.cancel} >Cancelar</Button>
                </CardActions>
            }
        </Card>
    )
}

export default AppointmentCard;