import createApi from 'API';
import { AuthModel } from 'models/AuthModel';
import { RegisterModel } from 'models/RegisterModel';
import { ResetPasswordModel } from 'models/ResetPasswordModel';

let endpoint = (window as { [key: string]: any })["runConfig"].api.auth as string;

const api = createApi({
    baseURL: endpoint
});

export enum SaveResult {
    ok = 0,
    dniExists = 1
}

export const logIn = async (dni: string, password: string): Promise<AuthModel | null> => {
    return api.post('authenticate', { dni, password })
        .then(res => {
            if (res.status === 200)
                return res.data as AuthModel;
            return null;
        })
        .catch(error => {
            return null;
        });
};

/**registra un nuevo usuario */
export const register = async (data: RegisterModel): Promise<SaveResult> => {
    return api.post('register', data)
        .then(res => {
            return res.data as SaveResult;
        });
};


/**registra un nuevo usuario */
export const recoverPassword = async (dni: string): Promise<void> => {
    return api.post('recover-password', { dni: dni })
        .then();
};


/**registra un nuevo usuario */
export const resetPassword = async (data: ResetPasswordModel): Promise<boolean> => {
    return api.post('reset-password', data)
        .then(res => {
            return res.data as boolean;
        });
};

/**verifica que el token de recupero de clave sea valido */
export const tokenIsValid = async (token: string): Promise<boolean> => {
    return api.get(`validate-token/${token}`)
        .then(res => {
            return res.data as boolean;
        })
};



