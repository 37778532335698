import React from "react";
import { Outlet } from "react-router-dom";
import useStyles from "./style";
import logo from 'assets/logo.jpg'

const Layout = (props: any) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div style={{ width: '100%', height: '2rem' }}></div>
            <div className={classes.imgContainer}>
                <img src={logo} className={classes.img}></img>
            </div>
            <div style={{ width: '100%', height: '3rem' }}></div>
            <Outlet></Outlet>
        </div>
    )
}

export default Layout;