import useStyles from './style';
import { Button, ButtonProps } from '@mui/material';

const   ButtonContained = (props: ButtonProps) => {
    const { children, ...otherProps } = props;
    const classes = useStyles();

    return (
        <Button className={`${classes.button}`} variant="contained" {...otherProps}>{children}</Button>
    );
}

export default ButtonContained;