import React, { useEffect } from 'react';
import Loader from 'components/Loader';
import useAuthContext from 'hooks/useAuth';

const Logout = (props: any) => {
    const { logout } = useAuthContext();

    useEffect(() => {
        logout();
        window.location.href='/';
    }, [])

    return (
        <>
            <Loader></Loader>
        </>
    )
};

export default Logout;