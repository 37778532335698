import { IconButton, Snackbar as MuiSnackbar, SnackbarOrigin, SnackbarProps } from '@mui/material';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import { Close } from '@mui/icons-material';
import React, { createContext, useState } from 'react';

export interface SnackbarOptions {
    open: boolean,
    message: string,
    severity?: AlertColor,
    duration?: number,
    anchorOrigin?: SnackbarOrigin
}

export const SnackBarContext = createContext({
    open: (options: SnackbarOptions) => { }
});

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const SnackbarContextProvider = (props: any) => {
    const [snackOptions, setSnackOptions] = useState<SnackbarOptions>();

    /**abre el snack */
    const open = (options: SnackbarOptions) => setSnackOptions(options)

    /**cierra el snack */
    const close = () => setSnackOptions({ open: false, message: '' })

    const contextValue = {
        open
    }

    return (
        <SnackBarContext.Provider value={contextValue}>
            <MuiSnackbar
                anchorOrigin={snackOptions?.anchorOrigin ||
                {
                    vertical: "bottom",
                    horizontal: "left"
                }}
                open={snackOptions?.open}
                autoHideDuration={snackOptions?.duration || 4000}
                onClose={close}
                action={
                    <IconButton key="close" onClick={close}>
                        <Close fontSize="small" />
                    </IconButton>}>
                <Alert onClose={close} severity={snackOptions?.severity} sx={{ width: '100%' }}>
                    {snackOptions?.message}
                </Alert>
            </MuiSnackbar>
            {props.children}
        </SnackBarContext.Provider>
    );
}

export default SnackBarContext;
