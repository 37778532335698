import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import palette from 'style-variables';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        title: {
            color: `${palette.$primary_text}!important`,
            fontSize: '1.2rem!important'
        },
        bottomMessage: {
            color: `${palette.$primary_text}!important`,
            padding: '2rem',
            textAlign: 'center'
        }   
    })
);
export default useStyles;