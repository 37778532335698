import React, { useEffect } from 'react';
import { height } from '@mui/system';
import { Grid } from '@mui/material';
import palette from 'style-variables';

const Loader = (props: any) => {



    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{
                height: '100vh',
             //   backgroundImage: `url(${back})`,
                backgroundSize: '100% 100%',
                backgroundColor: palette.$primary
            }}>
        </Grid>

    );
}
export default Loader;