import react from 'react';
import { TextField as MuiTextField, BaseTextFieldProps } from '@mui/material';
import { Control, Controller, UseFormRegisterReturn } from 'react-hook-form';


interface InputProp extends BaseTextFieldProps {
    control: Control<any, object>
    register: UseFormRegisterReturn
}

const Input = (props: InputProp & any) => {
    const { register, control, ...otherProps } = props;

    return (

        <Controller
            name={otherProps.name ? otherProps.name : ''}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                    <MuiTextField
                        {...register}
                        onChange={onChange}
                        {...otherProps}
                        error={!!error}
                        value={value}
                        helperText={error ? error.message : null}
                    ></MuiTextField>
                )
            }}
        />
    )
}

export default Input;