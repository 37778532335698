import React, { useRef, useState } from 'react';
import { AccountCircle, Email, Password, Phone } from '@mui/icons-material';
import { Button, Grid, InputAdornment, Typography } from '@mui/material';
import Input from 'components/Form/Input';
import { useForm } from 'react-hook-form';
import useStyles from './style';
import BackButton from 'components/Buttons/BackButton';
import { RegisterModel } from 'models/RegisterModel';
import * as authApi from 'API/auth';
import useSnackbarContext from 'hooks/useSnackbar';
import { Messages, REGEX } from 'settings';
import Backdrop from 'components/Backdrop';
import { useNavigate } from 'react-router-dom';

interface RegisterData extends RegisterModel {
    repeatEmail: string,
    repeatPassword: string
}

const Register = () => {
    const [working, setWorking] = useState(false);
    const { control, register, handleSubmit, reset, watch } = useForm<RegisterData>();
    const { open: openSnack } = useSnackbarContext();
    const classes = useStyles();
    const navigate = useNavigate();
    const password = useRef({});
    const email = useRef({});
    password.current = watch("password", "");
    email.current = watch("email", "");

    const onSubmit = (data: RegisterData) => {
        var d = data as RegisterData;
        authApi.register(d)
            .then(res => {
                switch (res) {
                    case authApi.SaveResult.ok:
                        openSnack({ open: true, severity: 'success', message: 'Registrado con éxito' });
                        reset();
                        navigate('/login');
                        break;
                    case authApi.SaveResult.dniExists:
                        openSnack({ open: true, severity: 'success', message: 'El dni ya existe' });
                        break;
                }
            })
            .catch(() => openSnack({ open: true, severity: 'error', message: Messages.ERROR_COMM }))
            .finally(() => setWorking(false));
    }

    return (
        <div className={classes.root}>
            <Backdrop open={working} />

            <Grid container justifyContent='center' alignItems={'center'}>
                <Grid item>
                    <Typography className={classes.title}>Completá tus datos</Typography>
                </Grid>
            </Grid>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={{ xs: 3, sm: 2 }} justifyContent='center' alignItems={'center'} flexDirection={{ xs: 'column', sm: 'row' }}>
                    <div style={{ width: '100%', height: '1rem' }}></div>
                    <Grid item xs={12} sm={6}>
                        <Input
                            fullWidth
                            name='firstname'
                            label='Nombre'
                            register={register('firstname', {
                                required: 'Este campo es requerido',
                                maxLength: { value: 25, message: '25 caracteres como máximo' }
                            })}
                            control={control}
                            variant={'filled'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <AccountCircle />
                                    </InputAdornment>
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            fullWidth
                            name='lastname'
                            label='Apellido'
                            register={register('lastname', {
                                required: 'Este campo es requerido',
                                maxLength: { value: 25, message: '25 caracteres como máximo' }
                            })}
                            control={control}
                            variant={'filled'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <AccountCircle />
                                    </InputAdornment>
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            fullWidth
                            name='dni'
                            label='DNI'
                            register={register('dni', {
                                required: 'Este campo es requerido',
                                pattern: { value: REGEX.REGEX_NUMBER_NATURAL, message: 'No es un dni' },
                                maxLength: { value: 8, message: '8 caracteres como máximo' }
                            })}
                            control={control}
                            variant={'filled'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <AccountCircle />
                                    </InputAdornment>
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            fullWidth
                            name='phone'
                            label='Teléfono'
                            register={register('phone', {
                                required: 'Este campo es requerido',
                                maxLength: { value: 13, message: '13 caracteres como máximo' }
                            })}
                            control={control}
                            variant={'filled'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <Phone />
                                    </InputAdornment>
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            fullWidth
                            name='email'
                            label='Correo electrónico'
                            register={register('email', {
                                required: 'Este campo es requerido',
                                maxLength: { value: 60, message: '60 caracteres como máximo' }
                            })}
                            control={control}
                            variant={'filled'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <Email />
                                    </InputAdornment>
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            fullWidth
                            name='repeatEmail'
                            label='Repetir correo electrónico'
                            register={register('repeatEmail', {
                                required: 'Este campo es requerido',
                                maxLength: { value: 60, message: '60 caracteres como máximo' },
                                validate: value => {
                                    var p = watch('email') || ''; return value === p || 'Las emails no son iguales'
                                }
                            })}
                            control={control}
                            variant={'filled'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <Email />
                                    </InputAdornment>
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            fullWidth
                            type={'password'}
                            name='password'
                            label='Contraseña'
                            register={register('password', { required: 'Este campo es requerido' })}
                            control={control}
                            variant={'filled'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <Password />
                                    </InputAdornment>
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            fullWidth
                            type={'password'}
                            name='repeatPassword'
                            label='Repetir contraseña'
                            register={register('repeatPassword', {
                                validate: value => {
                                    var p = watch('password') || ''; return value === p || 'Las contraseñas no son iguales'
                                }
                            })}
                            control={control}
                            variant={'filled'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <Password />
                                    </InputAdornment>
                            }}
                        />
                    </Grid>


                    <Grid item>
                        <Button variant='contained' type='submit' onClick={() => handleSubmit(onSubmit)}>registrarme</Button>
                    </Grid>

                    <div style={{ width: '100%', height: '3rem' }}></div>
                    <Grid item >
                        <BackButton></BackButton>
                    </Grid>
                </Grid>

            </form>
        </div>
    )
};

export default Register;