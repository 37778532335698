import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import Layout from 'pages/private/Layout';
import Home from 'pages/private/Home';

import PublicLayout from 'pages/public/Layout';
import Logout from 'pages/public/Logout';
import Login from 'pages/public/Login';
import Register from 'pages/public/Register';
import NotFound from 'pages/public/NotFound';

import useAuthContext from 'hooks/useAuth';
import RecoverPassword from 'pages/public/RecoverPassword';
import Profile from 'pages/private/Profile';
import ChangePassword from 'pages/private/ChangePassword';
import NewAppointment, { Search, Choose, Confirm } from 'pages/private/NewAppointment';
import Appointments from 'pages/private/Appointments';
import ResetPassword from 'pages/public/ResetPassword';

function App() {
  const { isLoading, auth, isAuth } = useAuthContext();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);

  }, [location])

  if (isLoading)
    return (
      <>Cargando...</>
    )

  return (
    <Routes>

      <Route path='/' element={<PublicLayout />}>
        <Route path='/' element={<Navigate to='login' replace />} />
        <Route path='login' element={<Login />} />
        <Route path='recover-password' element={<RecoverPassword />} />
        <Route path='reset-password/:token' element={<ResetPassword />} />
        <Route path='register' element={<Register />} />
        <Route path='not-found' element={<NotFound />} />
      </Route>
      <Route path='logout' element={<Logout />} />
      <Route path="*" element={<Navigate to="not-found" replace />} />

      {/* rutas del usuario activo // TODO validar usuario*/}
      {isAuth &&
        <Route path='client' element={<Layout />}>
          <Route path='client' element={<Navigate to="home" replace />} />
          <Route path='home' element={<Home />} />
          <Route path='profile' element={<Profile />} />
          <Route path='change-password' element={<ChangePassword />} />
          <Route path='appointments' element={<Appointments />} />
          <Route path='new-appointment' element={<NewAppointment />}>
            <Route path='' element={<Search />} />
            <Route path='choose' element={<Choose />} />
            <Route path='confirm' element={<Confirm />} />
          </Route>
        </Route>
      }

    </Routes>
  );
}

export default App;
