import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import palette from 'style-variables';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            minHeight: '100vh',
            background: palette.$background_not_logged
        },
        imgContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
        },
        img: {
            width: `28.12rem`,
            [theme.breakpoints.down('sm')]: {
                width: `90%`
            }
        }
    })
);
export default useStyles;