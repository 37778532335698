import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import palette from 'style-variables';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            [theme.breakpoints.up('xs')]:{
                '& form':{
                    padding:'2rem'
                }
            }
        },
        title: {
            color: `${palette.$primary_text}!important`,
            fontSize: '1.2rem!important'
        },
    })
);
export default useStyles;