import createApi from 'API';
import axios from 'axios';
import { MedicalSpecialityModel } from 'models/MedicalSpecialityModel';

let endpoint = (window as { [key: string]: any })["runConfig"].api.medicalCenter as string;

const api = createApi({
    baseURL: endpoint
});

export const list = async (professionalId?: number): Promise<MedicalSpecialityModel[]> => {
    return api.get(`medicalSpeciality/list${professionalId ? `/${professionalId}` : ''}`)
        .then(res => {
            return res.data as MedicalSpecialityModel[];
        });
};