import React from 'react';
import { Grid, Typography, Dialog, DialogContent, DialogActions, Slide } from '@mui/material';
import ButtonContained from 'components/Buttons/ButtonContained';
import useStyles from './style';
import clsx from "clsx";
import { TransitionProps } from '@mui/material/transitions';

interface ConfirmProps {
    message: string
    open: boolean,
    icon: any,
    shake?: boolean,
    close: () => void,
    confirm: () => void,
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const Confirm = (props: ConfirmProps) => {
    const classes = useStyles();

    const onConfirm = () => {
        props.confirm();
        props.close();
    }

    return (
        <>
            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                onClose={props.close}
                disableEscapeKeyDown={true}
            >
                <DialogContent>
                    <Grid container justifyContent={'center'} spacing={3}>
                        <Grid item container justifyContent={'center'}>
                            <Grid item>
                                <>
                                    <div
                                        className={clsx(classes.animatedItem, {
                                            [classes.animatedItemExiting]: props.open && props.shake
                                        })}
                                    >
                                        <props.icon sx={{ fontSize: '5rem' }} ></props.icon>

                                    </div>
                                </>
                            </Grid>
                        </Grid>
                        <Grid item container justifyContent={'center'}>
                            <Grid item>
                                <Typography>{props.message}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions >
                    <Grid item container justifyContent={'center'} spacing={1}>
                        <Grid item   >
                            <ButtonContained color='secondary' onClick={props.close}>CANCELAR</ButtonContained>
                        </Grid>
                        <Grid item  >
                            <ButtonContained onClick={onConfirm}>ACEPTAR</ButtonContained>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default Confirm;