import React from "react";
import { ButtonBaseProps, IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import palette from "style-variables";
import { useNavigate } from "react-router-dom";


interface BackButtonProps {
    onClick?: () => void
}

const BackButton = (props: BackButtonProps) => {
    const { onClick } = props;
    const navigate = useNavigate();

    return (
        <IconButton size="medium" color='secondary' onClick={() => navigate(-1)}
            style={{
                borderRadius: '10px',
                background: `${palette.$primary_text}`
            }}>
            <ArrowBackIcon />
        </IconButton>
    );

}

export default BackButton;