import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import App from 'App';
import { AuthContextProvider } from 'contexts/AuthContext';
import { SnackbarContextProvider } from 'contexts/SnackbarContext';
import palette from 'style-variables';
import { BrowserRouter, } from 'react-router-dom';
import 'dayjs/locale/es';
import updateLocale from 'dayjs/plugin/updateLocale';
import dayjs from 'dayjs';

const theme = createTheme({
  components: {
    // MuiButton: {
    //   styleOverrides: {
    //     root: {
    //       borderRadius: '0.625rem'
    //     }
    //   },
    //   defaultProps: {
    //     color: 'primary',
    //   }
    // },
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       '& fieldset': {
    //         borderColor: palette.$primary_normal,
    //         borderBlockColor: palette.$primary_normal,
    //         borderRadius: '0.625rem'
    //       },
    //       '& .MuiInputLabel-root': {
    //         color: palette.$primary_normal,
    //       },
    //     },
    //   },
    // },
    // MuiFormControl: {
    //   styleOverrides: {
    //     root: {
    //       '& fieldset': {
    //         borderColor: palette.$primary_normal,
    //         borderBlockColor: palette.$primary_normal,
    //         borderRadius: '0.625rem'
    //       },
    //       '& .MuiInputLabel-root': {
    //         color: palette.$primary_normal,
    //       },
    //     },
    //   }
    // },

  },

  palette: {
    primary: {
      main: palette.$primary_normal,
    },
    secondary: {
      main: palette.$secondary,
    },
    background: {
      default: palette.$background,
    },
    text: {
      primary: palette.$primary_normal,
      secondary: palette.$primary_text
    },
  },
});

const RootApp = () => {

  dayjs.locale('es');
  dayjs.extend(updateLocale)

  dayjs.updateLocale('es', {
    weekdays: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
    months: ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"]
  })

  return (
    <AuthContextProvider>
      <SnackbarContextProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <App></App>
          </BrowserRouter>
        </ThemeProvider >
      </SnackbarContextProvider>
    </AuthContextProvider>
  );

}

export default RootApp;
