import createApi from 'API';
import axios from 'axios';
import { ProfessionalModel } from 'models/ProfessionalModel';

let endpoint = (window as { [key: string]: any })["runConfig"].api.medicalCenter as string;

const api = createApi({
    baseURL: endpoint
});

export const list = async (speciality?: number): Promise<ProfessionalModel[]> => {
    return api.get(`professional/list${speciality ? `/${speciality}` : ''}`)
        .then(res => {
            return res.data as ProfessionalModel[];
        });
};