import { Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useStyles from "./style";
import Calendar, { CalendarTileProperties } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import dayjs from "dayjs";
import { KeyboardArrowLeft, KeyboardArrowRight, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ProfessionalModel } from "models/ProfessionalModel";
import { MedicalSpecialityModel } from "models/MedicalSpecialityModel";
import * as medicalSpecialityApi from 'API/medicalSpeciality';
import * as professionalApi from 'API/professional';
import * as scheduleApi from 'API/schedule';
import useSnackbarContext from "hooks/useSnackbar";
import Backdrop from "components/Backdrop";
import { ScheduleAvailabilityModel } from "models/ScheduleAvailabilityModel";

const Search = (prop: any) => {
    const classes = useStyles();
    const [professionalList, setProfessionalList] = useState<ProfessionalModel[]>([]);
    const [professional, setProfessional] = useState<ProfessionalModel>();
    const [specialityList, setSpecialityList] = useState<MedicalSpecialityModel[]>([]);
    const [speciality, setSpeciality] = useState<MedicalSpecialityModel>();
    const [availabilityList, setAvailabilityList] = useState<ScheduleAvailabilityModel[]>([]);
    const [professionalId, setProfessionalId] = useState('');
    const [specialityId, setSpecialityId] = useState('');
    const [value, onChange] = useState(new Date());
    const [working, setWorking] = useState(false);
    const navigate = useNavigate();
    const { open: openSnack } = useSnackbarContext();

    useEffect(() => {
        var prof: number | undefined = professionalId != '' ? Number(professionalId) : undefined;
        medicalSpecialityApi.list(prof)
            .then(setSpecialityList)
            .catch(() => openSnack({ open: true, message: 'Ocurrió un error al obtener especialidades', severity: 'error' }))
            .finally(() => setWorking(false));
    }, [professionalId])

    useEffect(() => {
        var spe: number | undefined = specialityId != '' ? Number(specialityId) : undefined;
        professionalApi.list(spe)
            .then(setProfessionalList)
            .catch(() => openSnack({ open: true, message: 'Ocurrió un error al obtener los profesionales', severity: 'error' }))
            .finally(() => setWorking(false));
    }, [specialityId])

    const handleChangeProfessional = (event: SelectChangeEvent) => {
        setProfessionalId(event.target.value);
        setProfessional(professionalList.filter(p => p.professionalId == Number(event.target.value))[0])
        setSpecialityId('');
        setAvailabilityList([]);
    };

    const handleChangeSpeciality = (event: SelectChangeEvent) => {
        setSpecialityId(event.target.value);
        setSpeciality(specialityList.filter(p => p.medicalSpecialityId == Number(event.target.value))[0])
        setProfessionalId('');
        setAvailabilityList([]);
    };

    const onSearch = () => {
        if (specialityId == '' && professionalId == '') {
            openSnack({ open: true, message: 'Debe seleccionar especialidad o profesional', severity: 'warning' })
            return;
        }

        var spe: number | undefined = specialityId != '' ? Number(specialityId) : undefined;
        var prof: number | undefined = professionalId != '' ? Number(professionalId) : undefined;

        setWorking(true);
        scheduleApi.listAvailability(spe, prof)
            .then(res => {
                if (res.length == 0)
                    openSnack({ open: true, message: 'No hay turnos disponinbles', severity: 'info' });
                setAvailabilityList(res);
            })
            .catch(() => openSnack({ open: true, message: 'Ocurrio un error al obtener turnos disponibles, vuelva a intentar', severity: 'error' }))
            .finally(() => setWorking(false));
    };

    const onSelectedDay = (value: Date, event: any) => {
        navigate(`/client/new-appointment/choose`,
            {
                state: {
                    date: value,
                    professionalId: professional?.professionalId,
                    professionalName: professional?.fullname,
                    specialityId: speciality?.medicalSpecialityId,
                    specialityName: speciality?.description
                }
            });
    };

    const tileDisabled = useCallback(({ date, view }: CalendarTileProperties) => {
        // Disable tiles in month view only
        if (view === 'month') {
            // Check if a date React-Calendar wants to check is on the list of disabled dates
            if (availabilityList.length == 0) //si no hay dis disponibles, devuelve el campo deshabilitado
                return true;
            return availabilityList.find(item => new Date(item.date).getTime() == date.getTime()) == undefined;
        }
        return false;
    }, [availabilityList])

    return (
        <>
            <Backdrop open={working}></Backdrop>
            <div className={classes.appointments}>
                <Grid container justifyContent={'center'} spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="filled" sx={{ m: 1, minWidth: 259 }} fullWidth>
                            <InputLabel id="lblSpeciality">Especialidad</InputLabel>
                            <Select
                                labelId="lblSpeciality"
                                id="speciality"
                                value={specialityId}
                                onChange={handleChangeSpeciality}
                            >
                                <MenuItem value="">Seleccionar</MenuItem>
                                {
                                    specialityList.map((item) => (
                                        <MenuItem key={item.medicalSpecialityId} value={item.medicalSpecialityId}>{item.description}</MenuItem>
                                    ))
                                }

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="filled" sx={{ m: 1, minWidth: 259 }} fullWidth>
                            <InputLabel id="lblProfessional">Profesional</InputLabel>
                            <Select
                                labelId="lblProfessional"
                                id="professional"
                                value={professionalId}
                                onChange={handleChangeProfessional}
                            >
                                <MenuItem value="">Seleccionar</MenuItem>
                                {
                                    professionalList.map((item) => (
                                        <MenuItem key={item.professionalId} value={item.professionalId}>{item.fullname}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={onSearch}>buscar</Button>
                    </Grid>
                </Grid>

                <div style={{ width: '100%', height: '2rem' }}></div>

                <Grid container justifyContent={'center'}>
                    {availabilityList.length > 0 &&
                        <Calendar
                            onClickDay={onSelectedDay}
                            className={classes.calendar}
                            onChange={onChange}
                            formatMonth={(_, date) => dayjs(date).format('MMMM')}
                            formatMonthYear={(_, date) => dayjs(date).format('MMMM YYYY')}
                            value={value}
                            tileDisabled={tileDisabled}
                            prevLabel={<KeyboardArrowLeft />}
                            prev2Label={<KeyboardDoubleArrowLeft />}
                            nextLabel={<KeyboardArrowRight />}
                            next2Label={<KeyboardDoubleArrowRight />}
                        />}
                </Grid>
            </div>
        </>
    )
}

export default Search;