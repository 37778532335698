import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import palette from 'style-variables';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            // height: '7.37rem', 
        },
        menuFrame: {

            '& .MuiList-root': {
                background: palette.$primary_menu,
                color: palette.$primary,
                borderBottomColor: 'red',
            },
            '& .MuiPaper-root': {
                borderRadius: '0.625rem',
            }
        },
        footer: {
            marginBottom: 'auto',
            bottom: 0,
            left: 0,
            width: 'auto',
            background: palette.$primary_normal,
            opacity: 1,
            display: 'flex',
            position: 'inherit',
            justifyContent: 'center',
            alignItems: 'center',
            height: '14rem',
        },
        logo: {
            width: `28.12rem`,
            [theme.breakpoints.down('sm')]: {
                width: '90%',
            },
        },
        menuResponsive: {
            '& .MuiList-root': {
                background: palette.$primary_menu,
                color: palette.$primary_white,
            },
            '& .MuiPaper-root': {
                borderRadius: '0.625rem',
            }
        }
    })
);
export default useStyles;