import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import palette from 'style-variables';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            color: `${palette.$primary_text}!important`,
            fontSize: '1.2rem!important'
        },
        user: {
            color: `${palette.$primary}!important`,
        },
        appointments: {
            padding: '2rem'
        },
        card: {
            border: `2px solid ${palette.$primary_text}`,
            borderRadius: '10px !important',
            '& .MuiCardContent-root': {
                padding: '2rem !important'
            },
            minWidth: '19rem',
            maxWidth: '19rem'
        }
    })
);
export default useStyles;