import React, { useState } from 'react';
import { AccountCircle } from '@mui/icons-material';
import { Button, Grid, InputAdornment, Typography } from '@mui/material';
import Input from 'components/Form/Input';
import { useForm } from 'react-hook-form';
import useStyles from './style';
import BackButton from 'components/Buttons/BackButton';
import { useNavigate } from 'react-router-dom';
import * as authApi from 'API/auth';
import Backdrop from 'components/Backdrop';

const RecoverPassword = (props: any) => {
    const { control, register, handleSubmit, reset } = useForm();
    const [working, setWorking] = useState(false);
    const classes = useStyles();
    const navigate = useNavigate();

    const onSubmit = (data: any) => {
        setWorking(true);
        authApi.recoverPassword(data.dni)
            .then(() => reset())
            .finally(() => {
                setWorking(false);
                navigate('/login');
            })
    }

    return (
        <div className={classes.root}>
            <Backdrop open={working}></Backdrop>

            <Grid container justifyContent='center' alignItems={'center'}>
                <Grid item>
                    <Typography className={classes.title}>Recuperá tu clave</Typography>
                </Grid>
            </Grid>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3} justifyContent='center' alignItems={'center'} flexDirection={'column'}>
                    <div style={{ width: '100%', height: '1rem' }}></div>
                    <Grid item xs={12}>
                        <Input
                            fullWidth
                            name='dni'
                            label='DNI'
                            register={register('dni', { required: 'Este campo es requerido' })}
                            control={control}
                            variant={'filled'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <AccountCircle />
                                    </InputAdornment>

                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant='contained' type='submit'>enviar</Button>
                    </Grid>
                    <div style={{ width: '100%', height: '1rem' }}></div>
                    <Grid item xs={12}>
                        <p className={classes.bottomMessage}>
                            Se te enviará un mail a la dirección registrada previamente con las instrucciones para reestablecer tu contraseña.
                        </p>
                    </Grid>

                    <div style={{ width: '100%', height: '3rem' }}></div>
                    <Grid item xs={12}>
                        <BackButton></BackButton>
                    </Grid>
                </Grid>

            </form>
        </div>
    )
};

export default RecoverPassword;