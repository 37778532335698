import React from 'react';

const NotFound = (props: any) => {
    return (
        <>
            <p>Ups! Página no encontrada</p>
        </>
    )
};

export default NotFound;