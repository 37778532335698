import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import palette from 'style-variables';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '16.56rem',
            border: `2px solid ${palette.$primary}`,
            borderRadius: '0px 0px 10px 10px !important',
            '& .MuiCardContent-root': {
                padding: '0px !important'
            }
        },
        rootHistory: {
            width: '16.56rem',
            border: `2px solid ${palette.$primary_text}`,
            borderRadius: '0px 0px 10px 10px !important',
            '& .MuiCardContent-root': {
                padding: '0px !important'
            }
        },
        header: {
            height: '1rem!important',
            background: `${palette.$primary}!important`,
            color: `${palette.$primary_white}!important`,
            textAlign: 'center'
        },
        headerHistory: {
            height: '1rem!important',
            background: `${palette.$primary_text}!important`,
            color: `${palette.$primary_white}!important`,
            textAlign: 'center'
        },
        date: {
            textAlign: 'center',
            color: palette.$primary_text,
            fontSize: '1.4rem!important',
            fontWeight: 'bold!important',
            margin: 0,
            padding: 0,
            lineHeight: '1.7rem!important'
        },
        time: {
            textAlign: 'center',
            color: palette.$primary_text,
            fontSize: '1rem!important',
            fontWeight: 'bold!important',
            margin: 0,
            padding: 0,
        },
        professional: {
            textAlign: 'center',
            color: palette.$primary_text,
            fontSize: '0.8rem!important',
            margin: 0,
            padding: 0,
            lineHeight: '1.7rem!important'
        },
        professionalName: {
            textAlign: 'center',
            color: palette.$primary_text,
            fontSize: '1rem!important',
            margin: 0,
            padding: 0,
            lineHeight: '1rem!important',
            textDecoration: 'underline'
        },
        cancel: {
            background: `${palette.$primary_text}!important`,
            borderRadius: '10px!important',
            height: '0.95rem!important'
        },
        cardContent:{
            height: '5rem!important'
        }
    })
);
export default useStyles;