import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import palette from 'style-variables';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        title: {
            color: `${palette.$primary_text}!important`,
            fontSize:'1.2rem!important'
        },
        forgotMyPass: {
            cursor: 'pointer',
            textDecoration: 'underline!important',
            color: `${palette.$primary_text}!important`,
        },
        signin: {
            cursor: 'pointer',
            color: `${palette.$primary}!important`,
            textDecoration: 'none',
            fontWeight: 'bold'
        }
    })
);
export default useStyles;