const palette = {
    $primary: '#0A9747',
    $background: '#FFFFFF',
    
    $secondary: '#DEDFE1',
    $background_not_logged: '#DEDFE1',
    
    $primary_normal: '#0A9747',
    $primary_text: '#595A5C',
    $primary_white: '#FFFFFF',
    $primary_menu: '#545C65',
}

export default palette;