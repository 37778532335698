import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import palette from 'style-variables';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalStyle: {
            borderRadius: '10px',
            display: 'flex',
            backgroundColor: palette.$primary,
            border: '0.125rem',
            padding:'2rem'
        },
        animatedItem: {
            animation: `$shake 1s cubic-bezier(.36,.07,.19,.97) both`
        },
        animatedItemExiting: {
            animation: `$shake 1s cubic-bezier(.36,.07,.19,.97) both`,
            transform: "translate3d(0, 0, 0)"
        },
        "@keyframes shake": {
            "10%, 90% ": {
                transform: "translate3d(-1px, 0, 0)"
            },
            "20%, 80%": {
                transform: 'translate3d(2px, 0, 0)'
            },
            '30%, 50%, 70%': {
                transform: 'translate3d(-4px, 0, 0)'
            },

            '40%, 60%': {
                transform: 'translate3d(4px, 0, 0)'
            }
        },
    })
);
export default useStyles;